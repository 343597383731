<template>
    <div class="min-h-screen bg-wood flex flex-col items-center justify-center px-4">
        <div class="w-full bg-white p-4 m-6 mb-16 font-script shadow-paper">
            <template v-if="loading">
                <div class="flex justify-center">
                    <img src="/img/Wedges-3s-200px.svg" style="height: 100px; width: 100px;">
                </div>
            </template>
            <template v-if="!loading">
                <h1 class=" text-5xl text-red-500 font-bold text-center">Liste de {{user?.username ?? '??'}}</h1>
                <template v-if="presents.length > 0">
                    <p class="text-xl text-gray-700 font-normal leading-relaxed">
                        <span class="mr-4"></span>Cher Père Noël, cette année j'ai été très sage ! Aussi je souhaiterais :
                    </p>
                    <ul>
                        <li v-for="present in presents" :key="present.id" class=" border-b border-blue-200">
                            <present-item :present="present" :username="user?.username ?? '??'" v-on:updatePresents="getPresents"/>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <p class="text-xl text-gray-700 font-normal leading-relaxed">
                        Pas de cadeaux pour le moment...
                    </p>
                </template>
            </template>
        </div>
        <navbar />
    </div>
</template>

<script>
import {  mapActions, mapState } from 'vuex';
import { supabase } from '../supabase';
import Navbar from '../components/Navbar.vue'
import PresentItem from '../components/presents/PresentItem.vue';
export default {
    name: 'ViewList',
    components: { Navbar, PresentItem },
    data() {
        return {
            user : null,
        }
    },
    computed:{
        ...mapState(['presents', 'loading'])
    },
    beforeMount() {
        const list_id = this.$route.params.id;
        this.getPresents(list_id);
        this.getUserId();
    },
    methods: {
        ...mapActions(['getPresents']),
        getUserId() {
            supabase.from('lists').select('profile_id').eq('id' , this.$route.params.id)
            .then(res => {
                this.getUser(res.data[0].profile_id);
            })
        },
        getUser(id) {
            supabase.from('profiles').select('*').eq('id' , id)
            .then(res => {
                this.user = res.data[0];
            })
        },
    },
}
</script>