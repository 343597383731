<template>
    <div class="flex flex-row items-center justify-around w-full bg-gray-100 fixed bottom-0 " style="max-width: 800px">
        <img @click="profil" :src="avatar" class="h-10 m-1 rounded-full cursor-pointer">
        <div @click="lists" class="flex flex-col justify-center items-center m-1 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
            </svg>
            <p class="text-black text-sm">Listes</p>
        </div>
        <div @click="gifts" class="flex flex-col justify-center items-center m-1 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
            </svg>
            <p class="text-black text-sm">Cadeaux</p>
        </div>
        <div v-if="ownList" @click="EditList" class="flex flex-col justify-center items-center m-1 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            <p class="text-black text-sm">Ma liste</p>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
export default {
    name: 'Navbar',
    data() {
        return {
            avatar: '/user.png',
        }
    },
    beforeMount() {
        if (this.user?.user_metadata?.avatar_url) {
            this.avatar = this.user.user_metadata.avatar_url;
        }
    },
    computed: {
        ...mapState(['user', 'ownList'])
    },
    methods: {
        profil: function () {
            this.$router.push({name: 'profil'});
        },
        lists: function () {
            this.purgePresents();
            this.$router.push({name: 'lists'});
        },
        gifts: function () {
            this.$router.push({name: 'gifts'});
        },
        EditList: function () {
            this.$router.push({name: 'editList', params: {id: this.ownList.id}});
        },
        ...mapActions(['purgePresents']),
    }
}
</script>