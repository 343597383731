<template>
    <div>
        <button class="text-xl text-left w-full text-black pt-2 pb-0 rounded hover:bg-gray-300 font-script" type="button" style="transition: all .15s ease" v-on:click="toggleModal()">
            <div class="flex flex-row justify-between items-end">
                <p>{{present.title}}</p>
                <p>{{present.done ?'✅':'⬜'}}</p>
            </div>
        </button>
        <div v-if="showEdit" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-full" style="max-width: 600px">
                <div class="relative px-4 py-10 bg-white shadow rounded-3xl sm:p-10">
                    <div v-if="!toTake" class="max-w-md mx-auto text-xl">
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 w-full font-semibold self-start text-gray-700">
                                <h2 class="leading-relaxed text-2xl">{{present.title}}</h2>
                                <p v-if="present.done" class="text-lg text-red-500 font-normal leading-relaxed">Pris par {{doneBy}}</p>
                                <div class="flex flex-row justify-between items-center">
                                    <p v-if="present.price" class="font-normal">Prix : <span class="font-bold text-blue-400">{{present.price}}€</span></p>
                                    <a v-if="present.link" :href="present.link" target="_blank">
                                        <button class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center text-white px-2 py-1 rounded-md focus:outline-none">
                                            Lien du cadeau
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                            </svg>    
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="present.comment" class="flex flex-col pl-2 mt-4">
                            <blockquote class="leading-loose bg-blue-100 rounded p-2 max-h-64 overflow-scroll">{{present.comment}}</blockquote>
                            <figcaption>- {{username}}</figcaption>
                        </div>
                        <div class="pt-4 flex items-center justify-end space-x-4">
                            <button v-if="!present.done" v-on:click="takePresent" class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center w-full text-white px-3 py-2 rounded-md focus:outline-none">Je prends ! </button>
                            <button class="flex justify-end items-center bg-gray-200 text-gray-900 px-3 py-2 rounded-md focus:outline-none" v-on:click="toggleModal()">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Fermer
                            </button>
                        </div>
                    </div>
                    <div v-if="toTake" class="max-w-md mx-auto text-xl">
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 w-full font-semibold self-start text-gray-700">
                                <h2 class="leading-relaxed text-2xl">Es-tu sûre de vouloir prendre ce cadeau ?</h2>
                                <div class="flex flex-row justify-between items-center">
                                    <p class=" text-xl font-normal">{{present.title}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2 mt-4">
                            <input id="confirmTake" v-model="confirm" type="checkbox" class="mr-2 cursor-pointer">
                            <label for="confirmTake" class="cursor-pointer">Oui je prends ce cadeau</label>
                        </div>
                        <div v-if="message" class="pt-2 flex items-start justify-start space-x-4">
                            <p class="text-red-500 text-lg">{{message}}</p>
                        </div>
                        <div class="pt-4 flex items-center justify-end space-x-4">
                            <button v-on:click="toTakePresent" class="bg-blue-500 flex justify-center items-center w-full text-white px-3 py-2 rounded-md focus:outline-none">Confirmer</button>
                            <button class="flex justify-end items-center bg-gray-200 text-gray-900 px-3 py-2 rounded-md focus:outline-none" v-on:click="toggleModal()">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showEdit" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
import { supabase } from '../../supabase';
import { mapState} from 'vuex';
export default {
    name: 'PresentItem',
    props: {
        present: {
            type: Object,
            required: true
        },
        username: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showEdit: false,
            toTake: false,
            confirm: false,
            doneBy: null,
            message: null,
        }
    },
    beforeMount() {
        this.getUser();
    },
    computed: {
        ...mapState(['user', 'profil'])
    },
    methods: {
        toggleModal() {
            this.showEdit = !this.showEdit
            this.confirm = false
            this.toTake = false
            this.message = null
        },
        takePresent() {
            this.toTake = true
        },
        toTakePresent() {
            this.message = null;
            if (this.confirm) {
                this.updatePresent();
                return this.toggleModal();
            }
            return this.message = '👆 Vous devez confirmer pour prendre ce cadeau';
        },
        getUser() {
            if (this.present.done) {
                supabase.from('profiles').select('*').eq('id' , this.present.done_by)
                .then(res => {
                    this.doneBy = res.data[0].username;
                })

            }
        },
        updatePresent() {
            const update = { ...this.present };
            supabase.from('presents').upsert({ 
                id : update.id,
                list_id : update.list_id,
                title : update.title,
                price : update.price,
                link : update.link,
                comment : update.comment,
                done : true,
                done_by : this.user.id,
            }, { onConflict: 'id' })
            .then(() => {
                this.$emit('updatePresents', update.list_id);
                this.doneBy = this.profil;
            })
        },
    },
}
</script>